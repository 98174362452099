.modalWrapper {
  z-index: 999;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(black, 0.6);
}

.modal {
  opacity: 100;
  position: fixed;
  top: 40%;
  left: 50%;
  transform: translate(-40%, -50%);
  width: 500px;
  height: 214px;
  background: #ffffff;
  border-radius: 10px;
}

.close {
  display: flex;
  justify-content: flex-end;
  padding: 20px;
  img {
    cursor: pointer;
  }
}

.btnBlock {
  display: flex;
  justify-content: center;
  gap: 20px;
  button {
    margin-top: 0;
  }
}

.modalText {
  margin-top: 20px;
  margin-bottom: 40px;

  display: flex;
  justify-content: center;
}
