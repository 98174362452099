.trends {
  width: 1140px;
  position: relative;
  padding-bottom: 155px;
}

.trends__wrapper {
  padding-top: 100px;
  padding-bottom: 100px;
}

.trends__title {
  width: 290px;
  height: 106px;
  grid-area: a;
  z-index: 1;
}

.trends__background {
  position: absolute;
  top: 0;
  left: -150px;
  z-index: 0;
}

.trends__imgs__wrapper {
  display: grid;
  grid-template-areas:
    "a b b"
    "c d ."
    "c d ."
    "e f f";
  grid-template-columns: 1fr 1fr 1fr;
  row-gap: 60px;
}

.trends__text0 {
  margin-left: -17%;
  z-index: 1;
}

.trends__text__title {
  width: 345px;
  height: 24px;
}

.trends__picture__wrapper {
  z-index: 1;
}

.trends__picture0 {
  width: 540px;
  height: 230px;
  margin-left: 5.8%;
}

.trends__picture1 {
  width: 485px;
  height: 230px;
  z-index: 1;
}

.trends__picture2 {
  width: 485px;
  height: 230px;
  margin-left: 16%;
  margin-top: 10%;
}

.trends__picture3 {
  width: 390px;
  height: 230px;
}

.trends__text1 {
  margin-top: 30px;
  z-index: 1;
  order: 1;
}

.trends__text2 {
  margin-left: 16%;
  margin-top: 30px;
  z-index: 1;
  order: 1;
}

.trends__text3 {
  margin-left: -17%;
  z-index: 1;
}

.trends__text__description0 {
  width: 345px;
  height: 95px;
  margin-top: 15px;
}

.trends__text__description1 {
  width: 485px;
  height: 76px;
  margin-top: 15px;
}

.trends__text__description2 {
  width: 485px;
  height: 57px;
  margin-top: 15px;
}

.trends__text__description3 {
  width: 345px;
  height: 76px;
  margin-top: 15px;
}

.trends__text__price {
  font-family: Gilroy;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: var(--brown-color);
  margin-top: 64px;
}

.trends_text_price_decorator3 {
  margin-top: 83px;
}

.more__trends {
  border: 1px solid var(--dark-grey-color);
  width: 290px;
  height: 123px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  grid-area: e;
  margin-top: 110px;
  text-decoration: none;
  z-index: 1;
  &:hover {
    border: 1px solid var(--white-color);
    cursor: pointer;
    .more__trends__logo {
      opacity: 1;
    }
  }
}

.more__trends__text {
  width: 124px;
  height: 18px;
  text-transform: uppercase;
  color: var(--white-color);
}

.more__trends__logo {
  margin-top: 15px;
  opacity: 0.5;
}

.trends__button {
  position: absolute;
  right: 0;
  width: 390px;
  margin-top: -6px;
  z-index: 1;
}

.trends__item0 {
  display: flex;
  justify-content: space-between;
  grid-area: b;
}

.trends__item1 {
  display: flex;
  flex-direction: column;
  grid-area: c;
}

.trends__item2 {
  display: flex;
  flex-direction: column;
  grid-area: d;
}

.trends__item3 {
  display: flex;
  grid-area: f;
  justify-content: space-between;
}

@media screen and (max-width: 1438px) {
  .trends__picture0 {
    width: 400px;
  }
}


@media screen and (max-width: 1173px) {
  .trends__picture0 {
    width: 360px;
    margin-left: 0;
  }
}

@media screen and (max-width: 1139px) {
  .trends {
    background: rgba(16, 32, 48, 1);
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100vw;
    padding-bottom: 40px;
  }
  .trends__wrapper {
    width: 668px;
    padding-bottom: 40px;
    padding-top: 40px;
  }
  .trends__imgs__wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .trends__item0 {
    flex-direction: column;
    z-index: 1;
  }
  .trends__item3 {
    flex-direction: column;
    z-index: 1;
  }
  .trends__button {
    position: relative;
    z-index: 1;
  }
  .trends__picture0 {
    width: 668px;
    height: 330px;
    margin-left: 0;
    margin-top: 0;
    z-index: 1;
  }
  .trends__picture1 {
    width: 668px;
    height: 330px;
    margin-left: 0;
    margin-top: 0;
    z-index: 1;
  }
  .trends__picture2 {
    width: 668px;
    height: 330px;
    margin-left: 0;
    margin-top: 0;
    z-index: 1;
  }
  .trends__picture3 {
    width: 668px;
    height: 330px;
    margin-left: 0;
    margin-top: 0;
    z-index: 1;
  }
  .trends__text__description0 {
    width: 668px;
    height: 57px;
    z-index: 1;
  }
  .trends__text__description1 {
    width: 668px;
    height: 57px;
    z-index: 1;
  }
  .trends__text__description2 {
    width: 668px;
    height: 57px;
    z-index: 1;
  }
  .trends__text__description3 {
    width: 668px;
    height: 57px;
    z-index: 1;
  }
  .trends__text0 {
    margin-left: 0;
    margin-top: 30px;
    z-index: 1;
    order: 1;
  }
  .trends__text2 {
    margin-left: 0;
    margin-top: 30px;
    z-index: 1;
    order: 1;
  }
  .trends__text3 {
    order: 1;
    margin-left: 0;
    margin-top: 30px;
  }
  .trends_text_price_decorator3 {
    margin-top: 73px;
  }
  .trends__line {
    width: 668px;
    margin-top: 20px;
    z-index: 1;
  }
  .more__trends {
    margin-top: -30px;
    margin-left: 190px;
  }
  .trends__button {
    z-index: 1;
  }
  .trends__background {
    display: none;
  }
}

@media screen and (max-width: 667px) {
  .trends__wrapper {
    width: 395px;
    padding-top: 0;
  }
  .trends__title {
    margin-top: 40px;
  }
  .trends__picture0 {
    width: 425px;
    height: 230px;
    margin-left: -15px;
  }
  .trends__picture1 {
    width: 425px;
    height: 230px;
    margin-left: -15px;
  }
  .trends__picture2 {
    width: 425px;
    height: 230px;
    margin-left: -15px;
  }
  .trends__picture3 {
    width: 425px;
    height: 230px;
    margin-left: -15px;
  }
  .trends__text__title {
    width: 395px;
  }
  .trends__text__description0 {
    width: 395px;
    height: 114px;
  }
  .trends__text__description1 {
    width: 395px;
    height: 114px;
  }
  .trends__text__description2 {
    width: 395px;
    height: 114px;
  }
  .trends__text__description3 {
    width: 395px;
    height: 114px;
  }
  .trends__line {
    width: 395px;
  }
  .trends__text__price {
    margin-top: 37px;
  }
  .more__trends {
    width: 395px;
    margin-top: 40px;
    margin-left: 0;
  }
  .trends__button {
    width: 395px;
    margin-top: 40px;
  }
  .button {
    width: 395px;
  }
}

@media screen and (max-width: 424px) {
  .trends__wrapper {
    width: 345px;
    padding-top: 0;
  }
  .trends__title {
    margin-top: 40px;
  }
  .trends__picture0 {
    width: 375px;
    height: 230px;
    margin-left: -15px;
  }
  .trends__picture1 {
    width: 375px;
    height: 230px;
    margin-left: -15px;
  }
  .trends__picture2 {
    width: 375px;
    height: 230px;
    margin-left: -15px;
  }
  .trends__picture3 {
    width: 375px;
    height: 230px;
    margin-left: -15px;
  }
  .trends__text__title {
    width: 345px;
  }
  .trends__text__description0 {
    width: 345px;
    height: 114px;
  }
  .trends__text__description1 {
    width: 345px;
    height: 114px;
  }
  .trends__text__description2 {
    width: 345px;
    height: 114px;
  }
  .trends__text__description3 {
    width: 345px;
    height: 114px;
  }
  .trends__line {
    width: 345px;
  }
  .trends__text__price {
    margin-top: 37px;
  }
  .more__trends {
    margin-top: -30px;
    margin-left: 0;
    width: 345px;
  }
  .trends__button {
    width: 345px;
    margin-top: -10px;
  }
  .button {
    width: 345px;
  }
}

@media screen and (max-width: 374px) {
  .trends__wrapper {
    width: 290px;
    padding-top: 0;
  }
  .trends__title {
    margin-top: 40px;
  }
  .trends__picture0 {
    width: 320px;
    height: 230px;
    margin-left: -15px;
  }
  .trends__picture1 {
    width: 320px;
    height: 230px;
    margin-left: -15px;
  }
  .trends__picture2 {
    width: 320px;
    height: 230px;
    margin-left: -15px;
  }
  .trends__picture3 {
    width: 320px;
    height: 230px;
    margin-left: -15px;
  }
  .trends__text__title {
    width: 290px;
  }
  .trends__text__description0 {
    width: 290px;
    height: 114px;
  }
  .trends__text__description1 {
    width: 290px;
    height: 114px;
  }
  .trends__text__description2 {
    width: 290px;
    height: 114px;
  }
  .trends__text__description3 {
    width: 290px;
    height: 114px;
  }
  .trends__line {
    width: 290px;
  }
  .trends__text__price {
    margin-top: 37px;
  }
  .more__trends {
    margin-top: -30px;
    margin-left: 0;
    width: 290px;
  }
  .trends__button {
    width: 290px;
    margin-top: -10px;
  }
  .button {
    width: 290px;
  }
}