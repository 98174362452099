* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  font-family: Gilroy;
}
::-webkit-scrollbar {
  width: 0;
}

.App {
  background: var(--dark-gradient);
  display: flex;
  flex-direction: column;
  align-items: center;
}

@font-face {
  font-family: "Gilroy";
  src: url(./fonts/Gilroy-Medium.ttf) format("truetype");
  font-weight: 500;
}

@font-face {
  font-family: "Gilroy";
  src: url(./fonts/Gilroy-Regular.ttf) format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Gilroy";
  src: url(./fonts/Gilroy-Semibold.ttf) format("truetype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Gilroy";
  src: url(./fonts/Gilroy-Light.ttf) format("truetype");
  font-weight: 300;
  font-style: normal;
}

.hover_class {
  &:hover {
    cursor: pointer;
    color: #f5f5f5;
  }
}

:root {
  --white-color: #f5f5f5;
  --grey-color: #dbdbdb;
  --dark-grey-color: #818090;
  --brown-color: #b49092;
  --black-color: #102030;
  --white-black-gradient: linear-gradient(
    257deg,
    #2c4460 -2.47%,
    rgba(44, 68, 96, 0.1) 102.24%
  );
  --white-brown-gradient: linear-gradient(
    270.36deg,
    #b49092 0.03%,
    rgba(180, 144, 146, 0) 100%
  );
  --brown-white-gradient: linear-gradient(
    180deg,
    #b49092 0%,
    rgba(180, 144, 146, 0.3) 100%
  );
  --dark-gradient: linear-gradient(
    194.5deg,
    #102030 0%,
    #102030 0.01%,
    #12283e 100%
  );
  --tablet-max-width: 1139px;
  --mobile-max-width: 667px;
}

.title {
  font-family: Gilroy;
  font-style: normal;
  font-weight: 300;
  font-size: 46px;
  line-height: 53px;
  color: var(--brown-color);
}

.item_title {
  font-family: Gilroy;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: var(--white-color);
}

.item_description {
  font-family: Gilroy;
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 120%;
  color: var(--grey-color);
}

.button_text {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;
}
