.header {
  width: 100%;
  position: absolute;

  .header__main__wrapper {
    display: flex;
    justify-content: center;
  }

  .header__wrapper {
    width: 100%;
    height: 49px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .logo {
    margin-left: 50px;
  }

  .under__header {
    border-top: 1px solid var(--dark-grey-color);
  }

  .header__icons {
    display: flex;
  }

  .facebook {
    margin-left: 34px;
  }

  .icon {
    width: 19px;
    height: 19px;
    opacity: 0.4;
    &:hover {
      opacity: 1;
    }
    &:first-child {
      margin-left: 0px;
    }
  }
}

.header__phone__icon__wrapper {
  display: none;
}

.phone__number {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #818090;
  text-decoration: none;
}

.header_fixed {
  position: fixed;
  top: 0;
  left: 0;
  animation-name: showHeader;
  animation-duration: 0.5s;
  background-color: #102030;
  z-index: 1000;
}

.header_hide {
  position: fixed;
  animation-name: hideHeader;
  animation-duration: 1s;
  opacity: 0;
  z-index: 1000;
  background-color: #102030;
}

@keyframes showHeader {
  0% {
    opacity: 0;
    top: -50px;
  }
  100% {
    opacity: 1;
    top: 0;
  }
}

@keyframes hideHeader {
  0% {
    opacity: 1;
    top: 0;
  }
  100% {
    opacity: 0;
    top: -50px;
    z-index: -1000;
  }
}

@media screen and (max-width: var(--tablet-max-width)) {
  .header__wrapper {
    max-width: 768px;
  }
  .header_fixed {
    min-width: 768px;
  }
  .under__header {
    min-width: 768px;
  }
}

@media screen and (max-width: 767px) {
  .header_fixed {
    min-width: 668px;
  }
  .under__header {
    min-width: 668px;
  }
}

@media screen and (max-width: 667px) {
  .header {
    width: 100vw;
    .logo {
      margin-left: 0;
    }
  }
  .header__wrapper {
    max-width: 425px;
    justify-content: space-between;
  }
  .header_fixed {
    min-width: 425px;
    max-width: 667px;
  }
  .under__header {
    min-width: 425px;
  }
  .phone__number {
    display: none;
  }
  .header__phone__icon__wrapper {
    display: inline;
    width: 19px;
    height: 19px;
    opacity: 0.6;
    &:hover {
      opacity: 1;
    }
  }
}

@media screen and (max-width: 424px) {
  .header {
    width: 100vw;
    .logo {
      margin-left: 0;
    }
  }
  .header__wrapper {
    max-width: 375px;
    justify-content: space-around;
  }
  .header_fixed {
    min-width: 320px;
    max-width: 425px;
  }
  .under__header {
    min-width: 320px;
  }
  .phone__number {
    display: none;
  }
  .header__phone__icon__wrapper {
    display: inline;
    width: 19px;
    height: 19px;
    opacity: 0.6;
    &:hover {
      opacity: 1;
    }
  }
}

@media screen and (max-width: 374px) {
  .header {
    width: 100vw;
    .logo {
      margin-left: 0;
    }
  }
  .header__wrapper {
    max-width: 320px;
    justify-content: space-around;
  }
  .header_fixed {
    min-width: 320px;
    max-width: 425px;
  }
  .under__header {
    min-width: 320px;
  }
  .phone__number {
    display: none;
  }
  .header__phone__icon__wrapper {
    display: inline;
    width: 19px;
    height: 19px;
    opacity: 0.6;
    &:hover {
      opacity: 1;
    }
  }
}