.page__not__found__wrapper {
  height: 55vh;
  width: 60%;
  margin-left: 35%;
  display: flex;
  align-items: flex-end;
  justify-content: space-around;
  .page__not__found__img {
    margin-bottom: 60px;
  }

  .page__not__found__text__wrapper {
    height: 192px;
  }

  .page__not__found__title {
    width: 88px;
    height: 35px;
    font-family: Gilroy;
    font-style: normal;
    font-weight: 300;
    font-size: 30px;
    line-height: 35px;
    color: var(--white-color);
  }

  .page__not__found__text {
    width: 345px;
    font-family: Gilroy;
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 21px;
    color: var(--white-color);
    margin-top: 10px;
  }

  .page__not__found__btn {
    margin-top: 50px;
  }
}

@media screen and (max-width: 1139px) {
  .page__not__found__wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    margin-left: 0;
    .page__not__found__img {
      margin-left: 0;
      margin-top: 150px;
    }
    .page__not__found__text {
      width: 390px;
      height: 21px;
    }
  }
}

@media screen and (max-width: 667px) {
  .page__not__found__wrapper {
    width: 290px;
    .page__not__found__text__wrapper {
      width: 290px;
      margin-top: -85px;
    }
    .page__not__found__text {
      width: 290px;
    }
  }
}
