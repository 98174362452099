$rootTextColor: #434343;

.adminWrapper {
  color: $rootTextColor;
  display: flex;
  flex-direction: column;
}

.contentWrapper {
  display: flex;
  flex-direction: row;
}

.pageContentWrapper {
  flex: 2;
  padding-right: 150px;
}
