.footer__wrapper {
  width: 100%;
  height: 156px;
  background: var(--black-color);
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin-top: 113px;
  .footer__content {
    width: 970px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  .footer__icons {
    margin-left: 55px;
    display: flex;
    margin-top: 35px;
    justify-content: space-between;
    width: 60px;
  }
  .icon {
    opacity: 0.4;
    &:hover {
      opacity: 1;
    }
  }
  .footer__logo__icons {
    max-width: 120px;
  }
  .footer__logo__icon {
    height: 33px;
  }
  .footer__map__phone__wrapper {
    width: 765px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
  }
  .footer__map__image {
    height: 156px;
    opacity: 0.6;
    &:hover {
      opacity: 1;
    }
  }
  .footer__map {
    width: 290px;
    height: 156px;
  }
  .phone__address__wrapper {
    margin-top: -35px;
  }
}

.under__footer {
  padding-top: 10px;
  padding-bottom: 10px;
  margin-left: 50%;
  font-size: 12px;
  display: flex;
  justify-content: center;
  font-family: "Gilroy";
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: var(--dark-grey-color);
  a {
    color: var(--dark-grey-color);
    text-decoration: none;
  }
  .policy {
    margin-left: 15px;
  }
}

.under_footer_hover:hover {
  text-decoration: underline;
}

.footer__address {
  color: var(--dark-grey-color);
  padding-bottom: 20px;
  text-decoration: none;
}
.footer__phone {
  color: var(--dark-grey-color);
  text-decoration: none;
}

.under__footer__line {
  border-top: 1px solid var(--dark-grey-color);
  width: 100%;
}

@media screen and (max-width: 1139px) {
  .footer__wrapper {
    height: 390px;
    margin-top: 200px;
    .footer__content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    .logo__icons__wrapper {
      width: 668px;
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      margin-left: 0;
    }
    .phone__address__wrapper {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      margin-top: -80px;
    }
    .footer__icons {
      margin: 0;
    }
    .footer__address {
      margin-top: 40px;
    }
    .phone__address {
      margin-left: -50px;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      }
    .footer__map {
      margin-top: 40px;
      margin-left: 0px;
    }
    .footer__map__phone__wrapper {
      width: 668px;
      justify-content: space-between;
    }
  }
  .under__footer {
    display: flex;
    justify-content: center;
    margin: 0;
  }
}

@media screen and (max-width: 667px) {
  .footer__wrapper {
    min-width: 425px;
    height: 400px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .footer__content {
      width: 100%;
    }
    .logo__icons__wrapper {
      width: 395px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
    .phone__address__wrapper {
      margin: 0;
      align-items: flex-start;
      margin-left: -75px;
    }
    .phone__address {
      margin-top: 40px;
      align-items: flex-start;
    }
    .footer__map__phone__wrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      width: 395px;
    }
    .footer__map {
      width: 395px;
      height: 220px;
      object-fit: cover;
    }
    .footer__address {
      margin-top: 80px;
    }
    .phone__address {
      margin-left: 0;
    }
  }
  .under__footer {
    width: 395px;
    flex-direction: column;
    align-items: flex-start;
    .policy {
      margin-left: 0;
      margin-top: 10px;
    }
  }
}

@media screen and (max-width: 424px) {
  .footer__wrapper {
    min-width: 375px;
    height: 400px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .footer__content {
      width: 100%;
    }
    .logo__icons__wrapper {
      width: 345px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
    .phone__address__wrapper {
      margin: 0;
      align-items: flex-start;
      margin-left: -75px;
    }
    .phone__address {
      margin-top: 0;
      align-items: flex-start;
    }
    .footer__map__phone__wrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      width: 345px;
    }
    .footer__map {
      width: 345px;
      height: 156px;
      object-fit: cover;
    }
  }
  .under__footer {
    width: 345px;
    flex-direction: column;
    align-items: flex-start;
    .policy {
      margin-left: 0;
      margin-top: 10px;
    }
  }
}

@media screen and (max-width: 374px) {
  .footer__wrapper {
    min-width: 320px;
    height: 400px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .footer__content {
      width: 100%;
    }
    .logo__icons__wrapper {
      width: 290px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
    .phone__address__wrapper {
      margin: 0;
      align-items: flex-start;
      margin-left: -75px;
    }
    .phone__address {
      margin-top: 0px;
      align-items: flex-start;
    }
    .footer__map__phone__wrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      width: 290px;
    }
    .footer__map {
      width: 290px;
      height: 156px;
      object-fit: cover;
    }
  }
  .under__footer {
    width: 290px;
    flex-direction: column;
    align-items: flex-start;
    .policy {
      margin-left: 0;
      margin-top: 10px;
    }
  }
}