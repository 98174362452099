.about__wrapper {
  margin-top: 200px;
  position: relative;
}

.about__title {
  width: 290px;
  height: 106px;
}
.about__logo {
  margin-left: 156px;
}
.about__background {
  top: -50px;
  position: absolute;
  display: flex;
  align-items: flex-start;
}
.about__list__wrapper {
  width: 621px;
  left: 33%;
  top: -150px;
  position: relative;
}
.about__item {
  display: flex;
  width: 621px;
}
.about__item__arrow {
  margin-top: -40px;
}
.about__item__title {
  margin-left: 20px;
  margin-top: 30px;
}
.about__item__description {
  width: 621px;
  height: 57px;
  margin-top: 10px;
  margin-left: 20px;
}

@media screen and (max-width: 1139px) {
  .about__wrapper {
    display: flex;
    flex-direction: column;
    width: 668px;
    padding-bottom: 0;
    margin-top: 100px;
  }
  .about__title {
    z-index: 1;
    width: 290px;
  }
  .about__background {
    top: 100px;
  }
  .about__list__wrapper {
    top: 0px;
    margin-left: -5%;
    width: 480px;
  }
  .about__background__img {
    width: 535px;
    z-index: 0;
  }
  .about__logo {
    margin-left: 0;
  }
  .about__item__arrow {
    margin-top: -30px;
  }
  .about__item__title {
    margin-top: 40px;
  }
  .about__item__description {
    width: 430px;
  }
  .about__item {
    width: 433px;
  }
  .about__text__wrapper {
    width: 430px;
  }
}

@media screen and (max-width: 667px) {
  .about__wrapper {
    width: 395px;
  }
  .about__logo {
    display: none;
  }
  .about__background {
    position: static;
  }
  .about__background__img {
    width: 265px;
    height: 650px;
    object-fit: cover;
    margin-left: -15px;
    z-index: 0;
  }
  .about__list__wrapper {
    position: static;
    width: 395px;
    margin-left: 0px;
    margin-top: -600px;
    z-index: 1;
  }
  .about__item {
    width: 395px;
  }
  .about__text__wrapper {
    width: 395px;
  }
  .about__item__description {
    width: 350px;
  }
}

@media screen and (max-width: 424px) {
  .about__wrapper {
    width: 335px;
  }
  .about__logo {
    display: none;
  }
  .about__background {
    position: static;
  }
  .about__background__img {
    width: 210px;
    height: 750px;
    object-fit: cover;
    margin-left: -15px;
    z-index: 0;
  }
  .about__list__wrapper {
    position: static;
    width: 345px;
    margin-left: 0px;
    margin-top: -700px;
    z-index: 1;
  }
  .about__item {
    width: 345px;
  }
  .about__text__wrapper {
    width: 345px;
  }
  .about__item__title {
    margin-top: 0;
  }
  .about__item__description {
    width: 300px;
    height: 133px;
  }
  .about__item__arrow {
    margin-top: -150px;
  }
}

@media screen and (max-width: 374px) {
  .about__wrapper {
    width: 280px;
  }
  .about__logo {
    display: none;
  }
  .about__background {
    position: static;
  }
  .about__background__img {
    width: 160px;
    height: 750px;
    object-fit: cover;
    margin-left: -15px;
    z-index: 0;
  }
  .about__list__wrapper {
    position: static;
    width: 290px;
    margin-left: 0px;
    margin-top: -700px;
    z-index: 1;
  }
  .about__item {
    width: 290px;
  }
  .about__text__wrapper {
    width: 290px;
  }
  .about__item__description {
    width: 246px;
  }
}