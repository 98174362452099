@mixin btn {
  margin-top: 20px;
  width: 105px;
  height: 35px;
  color: #ffffff;
  border: 1px solid transparent;
}

.button {
  @include btn;
  cursor: pointer;
  background-color: #00ce2d;
  &:active {
    background-color: #00bc29;
  }
}

.buttonRed {
  @include btn;
  cursor: pointer;
  background-color: #ff5151;
  &:active {
    background-color: #ff3838;
  }
}

.disabled {
  @include btn;
  background-color: #c4c4c4;
}

.buttonWrapper {
  display: flex;
  justify-content: center;
}
