$color-file-wrapper: #b9b9b9;
$color-label: #0085ff;

.fileWrapper {
  display: flex;
  flex-direction: column;
  color: $color-file-wrapper;
}

.fileTitle {
  font-weight: 400;
}
.sizesTitle {
  margin-top: 5px;
  font-size: 14px;
  font-weight: 300;
  width: 100%;
}

@mixin filebox {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid #e0e0e0;
  background: #ffffff;
  img {
    cursor: pointer;
  }
  input {
    display: none;
  }
  span {
    display: block;
    color: $color-label;
    cursor: pointer;
    &:hover {
      &:after {
        content: "";
        width: 54px;
        position: absolute;
        bottom: 40px;
        left: 28px;
        border-bottom: 1px solid $color-label;
      }
    }
  }
}

.fileBoxSmall {
  @include filebox;
  width: 100px;
  height: 100px;
}

.fileBoxBig {
  @include filebox;
  width: 150px;
  height: 150px;
}
