.blockWrapper {
  display: flex;
  gap: 20px;
  margin-top: 41px;
  align-items: flex-end;
  :first-child {
    width: 269px;
  }
  :last-child {
    flex: 2;
  }
}
