.main__section__wrapper {
  display: flex;
  max-width: 100vw;
  margin-top: 50px;
  margin-bottom: 14%;
  .main__section__list__wrapper {
    display: flex;
    flex-direction: column;
    // margin-right: 10.5%;
  }

  .main__section__list {
    margin-top: 51px;
    list-style-type: none;
    margin-left: 15%;
  }

  .main__section__list__item {
    width: 320px;
    height: 46px;
    margin-top: -23px;
    margin-left: 10px;
    font-family: "Gilroy";
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 23px;
    color: var(--white-color);
  }

  .main__section__list__item__background {
    font-family: "Gilroy";
    font-style: normal;
    font-weight: 600;
    font-size: 46px;
    line-height: 55px;
    background: var(--brown-white-gradient);
    background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .main__section__list__item__wrapper {
    padding-bottom: 20px;
  }

  .background__logo__wrapper {
    position: absolute;
    top: 470px;
    left: 0px;
    opacity: 0.3;
  }
}

.main__section__button {
  display: flex;
  max-width: 390px;
  max-height: 55px;
  margin-top: 10%;
}

.main__section__video__PC {
  width: 900px;
  height: 480px;
  margin-left: -150px;
  position: relative;
  & video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
.main__section__video__phone {
  position: relative;
  & video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

@media screen and (max-width: 1139px) {
  .main__section__wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 120px;
  }
  .main__section__video__PC {
    width: 768px;
    height: 480px;
    margin-left: 0;
  }
  .main__section__button {
    position: relative;
    left: 30%;
    top: -350px;
    margin-top: 0;
    width: 390px;
    height: 55px;
  }
  .background__logo__wrapper {
    top: 370px;
  }
  .main__section__list__wrapper {
    margin-right: 0;
  }
  .main__section__list {
    display: flex;
    flex-wrap: wrap;
    margin-top: 95px;
    list-style-type: none;
  }
  .main__section__list__wrapper {
    width: 668px;
  }
  .main__section__list__item__wrapper {
    width: 235px;
  }
}

@media screen and (max-width: 767px) {
  .main__section__video__PC {
    width: 668px;
  }
}

@media screen and (max-width: 667px) {
  .main__section__wrapper {
    align-items: flex-start;
    margin-bottom: 120px;
    .main__section__video__phone {
      display: flex;
      width: 425px;
      height: 480px;
      object-fit: cover;
    }
    .main__section__button {
      width: 395px;
      height: 55px;
      margin-left: 0;
      top: -600px;
      left: 15px;
    }
    .background__logo__wrapper {
      top: 485px;
    }
    .background__logo__icon {
      width: 320px;
    }
    .main__section__list__wrapper {
      width: 290px;
      height: 290px;
    }
    .main__section__list__item {
      width: 280px;
    }
    .main__section__list {
      margin-left: 15px;
    }
  }
}

@media screen and (max-width: 424px) {
  .main__section__wrapper {
    .main__section__video__phone {
      width: 375px;
      height: 480px;
    }
    .main__section__button {
      left: 15px;
      width: 345px;
    }
    .button {
      width: 345px;
    }
    .main__section__list {
      margin-top: 80px;
    }
  }
}

@media screen and (max-width: 374px) {
  .main__section__wrapper {
    .main__section__video__phone {
      width: 320px;
      height: 480px;
    }
    .main__section__button {
      left: 15px;
      width: 290px;
    }
    .button {
      width: 290px;
    }
    .main__section__list {
      margin-top: 80px;
    }
  }
}