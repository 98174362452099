$darkblue: #102030;
@mixin input-border {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  height: 1px;
  width: 100%;
}

.pageWrapper {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.loginWrapper {
  margin-top: 200px;
  width: 290px;
  height: 250px;
}

.formWrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 30px;
}
.warning {
  color: red;
}

.input {
  font-family: Gilroy;
  font-weight: 300;
  margin: 0;
  padding: 10px;
  font-size: 16px;
  width: 290px;
  border: none;
  outline: none;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

.inputWrapper {
  position: relative;
  &:hover {
    &::after {
      @include input-border;
      background-color: $darkblue;
    }
  }
  &::after {
    @include input-border;
    background-color: #ebebeb;
  }
}
.buttonWrapper {
  margin-top: 10px;
  button {
    cursor: pointer;
    width: 290px;
    height: 55px;
    border: 1px solid $darkblue;
    color: white;
    background-color: $darkblue;

    &:hover {
      background-color: white;
      color: $darkblue;
    }
  }
}

.loginHeading {
  h3 {
    font-size: 24px;
    text-align: left;
    margin-bottom: 50px;
  }
}
