.contentWrapper {
  max-width: 1440px;
  min-width: 1140px;
}

@media screen and (max-width: 1139px) {
  .contentWrapper {
    min-width: 668px;
  }
}

@media screen and (max-width: 667px) {
  .contentWrapper {
    min-width: 395px;
  }
}

@media screen and (max-width: 424px) {
  .contentWrapper {
    min-width: 345px;
  }
}

@media screen and (max-width: 374px) {
  .contentWrapper {
    min-width: 290px;
  }
}