.phone__icon {
  background: linear-gradient(91.7deg, #b49092 0%, #b49092 100%);
  color: black;
  height: 60px;
  width: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
  animation: shake 1.5s cubic-bezier(0.36, 0.07, 0.19, 0.97) both infinite;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  perspective: 1000px;

  @keyframes shake {
    0% {
    }
    5%,
    25% {
      transform: translateX(-1px);
    }
    15%,
    35% {
      transform: translateX(2px);
    }
    45% {
      transform: translateX(-1px);
    }
  }
  &:hover {
    background: var(--white-color);
    cursor: pointer;
    .phone__icon__img {
      background: var(--white-color);
    }
  }
  .phone__icon__img {
    background: linear-gradient(91.7deg, #b49092 0%, #b49092 100%);
  }
}

.phone__button__wrapper {
  width: 60px;
  position: fixed;
  // left: 0;
  // right: 0;
  bottom: 150px;
  display: none;
  justify-content: flex-end;
  .phone__button__help__wrapper {
    display: flex;
    justify-content: flex-end;
    width: 60px;
  }
  .phone__button {
    border: none;
    background: none;
  }

  left: calc(1080px + (100vw - 1140px) / 2);

  @media screen and (max-width: 1139px) {
    left: calc(608px + (100vw - 668px) / 2);
  }

  @media screen and (max-width: 667px) {
    left: calc(335px + (100vw - 395px) / 2);
  }

  @media screen and (max-width: 424px) {
    left: calc(285px + (100vw - 345px) / 2);
  }

  @media screen and (max-width: 374px) {
    left: calc(230px + (100vw - 290px) / 2);
  }
}

.show {
  z-index: 15;
  display: flex;
}
