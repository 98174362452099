@mixin inp {
  font-size: 16px;
  width: 100%;
  padding: 10px;
  border: 1px solid #e0e0e0;
  background: #ffffff;
  outline: none;
  font-weight: 400;
  font-family: Gilroy;
  &:focus {
    border: 1px solid #363636;
  }
}
.label {
  color: #b9b9b9;
}

.input {
  @include inp;
  height: 40px;
}

.textarea {
  @include inp;
  height: 150px;
}
.error {
  color: red;
}
