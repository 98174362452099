$sidebar-backgroud: #f9f9f9;
$colorHoverNavItem: #ffffff;

@mixin navItem {
  display: flex;
  height: 43px;
  align-items: center;
  padding-left: 30px;
  font-weight: 600;
}

.sidebarWrapper {
  width: 217px;
  height: 100vh;
  background-color: $sidebar-backgroud;
}
.navList {
  margin-top: 18px;
}

.navItem {
  cursor: pointer;
  @include navItem;
  &:hover {
    background-color: $colorHoverNavItem;
  }
  &_active {
    @include navItem;
    background-color: $colorHoverNavItem;
  }
}
