.formWrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 50px;
}

.blockWrapper {
  :not(:last-child) {
    margin-bottom: 15px;
  }
}
