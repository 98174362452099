.headerWrapper {
  z-index: 10;
  height: 60px;
  width: 100%;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.15);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 150px 0 70px;
}

.logo {
  height: 19px;
  width: 65px;
  img {
    width: 100%;
  }
}
.logOut {
  cursor: pointer;
  font-weight: 400;
  &:hover {
    font-weight: 600;
  }
}
