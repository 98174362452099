.modal {
  z-index: 20;
  position: fixed;
  padding-left: 85px;
  padding-right: 85px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 545px;
  height: 348px;
  background: var(--white-color);
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;

  .modal__title {
    width: 375px;
    height: 24px;
    color: var(--black-color);
  }

  .form {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 75px;
    -webkit-appearance: none;
  }

  .input {
    width: 375px;
    border: none;
    border-bottom: 1px solid var(--dark-grey-color);
    background: var(--white-color) 080808;
    color: #2c4460;
    padding-left: 10px;
    padding-bottom: 7px;
    &:focus {
      outline: 0;
      border-bottom: 1px solid black;
      font-weight: 500;
    }
  }
  .close__modal {
    cursor: pointer;
    position: fixed;
    top: 35px;
    right: 30px;
    border: none;
    background: var(--white-color);
    width: 25px;
    height: 25px;
    opacity: 0.5;
    &:hover {
      opacity: 1;
    }
  }

  .invalid {
    border-bottom: 1px solid #ff3a3a;
    &::-webkit-input-placeholder {
      color: #ff3a3a;
    }
  }

  .correct {
    font-family: Gilroy;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: var(--black-color);
  }

  .error {
    position: absolute;
    color: #ff3a3a;
    font-family: Gilroy;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
  }

  .hide {
    display: none;
  }

  .submited__form__text {
    font-family: Gilroy;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 120%;
    color: #2c4460;
  }

  .submited__form__text2 {
    margin-top: 15px;
  }
}

.modal_submit_btn {
  width: 375px;
  height: 55px;
  background: #102030;
  font-family: Gilroy;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;
  color: var(--white-color);
  border: none;
  margin-top: 50px;
}

.modal_submit_btn:hover {
  background-color: var(--white-color);
  color: #102030;
  cursor: pointer;
  border: 1px solid #102030;
}

.modal_submit_btn:active {
  background: rgba(16, 32, 48, 0.1);
}

.bg__layer {
  position: absolute;
  top: 0;
  z-index: 15;
  width: 100%;
  height: 556vh;
  background: #000;
  opacity: 0.7;
}
@media screen and (max-width: 1139px) {
  .bg__layer {
    height: 1050vh;
  }
}

@media screen and (max-width: 667px) {
  .modal {
    width: 290px;
    height: 347px;
    padding-left: 15px;
    padding-right: 15px;
    .modal__title {
      width: 153px;
    }
    .input {
      width: 260px;
    }
    .close__modal {
      top: 40px;
      right: 20px;
    }
    .modal_submit_btn {
      width: 260px;
    }
  }
}
