.formWrapper {
}

.socialBlockWrapper {
  margin-bottom: 30px;
  :first-child {
    margin-bottom: 30px;
  }
}

.addressBlockWrapper {
  display: flex;
  gap: 31px;
  margin-bottom: 30px;
  flex-grow: 4;
  :first-child {
    flex: auto;
  }

  :last-child {
    flex-basis: 200px;
  }
}

.mapBlockWrapper {
  display: flex;
  gap: 30px;
  :first-child {
    img {
      width: 150px;
      height: 150px;
    }
  }

  :not(:first-child) {
    flex: auto;
  }
}
