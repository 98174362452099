.steps__wrapper {
  padding-bottom: 100px;
  display: flex;
  justify-content: flex-end;
}

.steps__title {
  width: 390px;
  height: 106px;
  grid-area: title;
}

.steps__block {
  margin-top: 30px;
  font-size: 40px;
}

.step__title {
  margin-top: -90px;
}

.step__description {
  margin-top: 15px;
  width: 290px;
  height: 76px;
}

.step__item__arrow {
  margin-top: 26px;
}

.step_item_arrow2 {
  margin-top: 34px;
}


.step__background {
  margin-top: 10px;
}

.steps__block__wrapper {
  display: grid;
  column-gap: 60px;
  grid-template-areas:
    "a . title"
    "b c ."
    ". d e";
}

.step__item0 {
  grid-area: a;
}

.step__item1 {
  grid-area: b;
}

.step__item2 {
  grid-area: c;
}

.step__item3 {
  grid-area: d;
}

.step__item4 {
  grid-area: e;
}

.steps__background {
  position: absolute;
  right: 0px;
  top: 120%;
}

@media screen and (max-width: 1139px) {
  .steps__wrapper {
    width: 668px;
  }
  .step__background {
    width: 260px;
    height: 130px;
  }
  .step__item__arrow {
    display: none;
  }
  .steps__block__wrapper {
    display: flex;
    flex-wrap: wrap;
    &:first-child {
      justify-content: flex-start;
    }
  }
  .step__item2 {
    margin-top: 50px;
  }
  .step__item3 {
    margin-top: 50px;
  }
  .step__item4 {
    margin-top: 50px;
    margin-left: 28%;
  }
}

@media screen and (max-width: 667px) {
  .steps__wrapper {
    width: 395px;
    padding-top: 150px;
  }
  .steps__title {
    width: 290px;
    height: 106px;
  }
  .steps__background {
    top: 140%;
  }
  .step__background {
    width: 395px;
  }
  .step__description {
    width: 395px;
  }
  .step__item0 {
    margin-top: 50px;
  }
  .step__item1 {
    margin-top: 20px;
  }
  .step__item2 {
    margin-top: 20px;
  }
  .step__item3 {
    margin-top: 20px;
  }
  .step__item4 {
    margin-top: 20px;
    margin-left: 0;
  }
}

@media screen and (max-width: 424px) {
  .steps__wrapper {
    width: 345px;
    padding-top: 150px;
  }
  .steps__title {
    width: 345px;
    height: 106px;
  }
  .step__background {
    width: 345px;
  }
  .step__description {
    width: 345px;
  }
}

@media screen and (max-width: 374px) {
  .steps__wrapper {
    width: 290px;
    padding-top: 150px;
  }
  .steps__title {
    width: 290px;
    height: 106px;
  }
  .step__background {
    width: 290px;
  }
  .step__description {
    width: 290px;
  }
}