.button {
  width: 390px;
  height: 55px;
  background-color: var(--white-color);
  border: 0px;
  color: var(--black-color);
  &:hover {
    cursor: pointer;
    border: 1px solid var(--white-color);
    background-color: var(--black-color);
    color: var(--white-color);
  }
  &:active {
    background: rgba(245, 245, 245, 0.1);
  }
}

@media (max-width: var(--mobile-max-width)) {
  .button {
    width: 290px;
    height: 55px;
  }
}
