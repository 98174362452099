.blockWrapper {
  display: flex;
}
.fileWrapper {
  margin-right: 20px;
  img {
    width: 100px;
    height: 100px;
  }
}
.inputsWrapper {
  flex: auto;
  :not(:last-child) {
    margin-bottom: 20px;
  }
}

.formWrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-row-gap: 120px;
  grid-column-gap: 50px;
}
